import ClientFooter from './components/sections/ClientFooter.vue';
import initI18n from './i18n';
import Vue from 'vue';

initI18n()
	.then(
		(i18n) => {
			window.i18n = i18n;
			new Vue({
				i18n,
				render: (h) => h(ClientFooter),
			}).$mount('#footer-app');
		});
